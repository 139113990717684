@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  width: 100%;
  overflow: hidden;
  color: #1b1e38;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.17979rem;
}
.container .loadingContainer {
  font-size: 0.15411rem;
  color: #7f82a0;
  display: flex;
  gap: 0.12842rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 0.85616rem;
}
.container .loadingContainer .loadingIcon {
  font-size: 0.30822rem;
  width: 0.30822rem;
  height: 0.30822rem;
}
.container .emptyContainer {
  font-size: 0.15411rem;
  color: #7f82a0;
  height: 0.85616rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .content {
  max-height: 4.28082rem;
  overflow: hidden;
  overflow-y: auto;
}
.container .footer {
  padding: 0.06849rem 0.27397rem;
}
.container .footer .footerAlert {
  font-size: 0.11986rem;
  background-color: #373a58;
  border-radius: 0.08562rem;
  padding: 0.08562rem 0.13699rem 0.07705rem;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.container .footer .footerAlert span {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.container .footer .footerIcon {
  font-size: 0.10274rem;
}
.container .searchButton {
  width: max-content;
  margin: 0 auto;
}