@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.skeleton_homeBanner .skeleton_homeBannerDesc .skeleton_baseDescSubTitle {
  height: 0.47945rem;
  margin-bottom: 0.13699rem;
  width: 2.14041rem;
}
.skeleton_homeBanner .skeleton_homeBannerDesc .skeleton_baseDescTitle {
  margin-bottom: 0.24829rem;
  height: 0.41952rem;
}