@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.11986rem;
  padding: 0.01712rem 0.06849rem 0.00856rem 0.07705rem;
}
.container .inner span {
  font-size: 0.11986rem;
  font-weight: 400;
  line-height: 0.14555rem;
}