@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.skeleton_baseDesc {
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.96918rem;
}
.skeleton_baseDesc .skeleton_baseDescWrap {
  display: flex;
  flex-direction: column;
}
.skeleton_baseDesc .skeleton_baseDescSubTitle {
  height: 0.11986rem;
  width: 1.71233rem;
}
.skeleton_baseDesc .skeleton_baseDescTitle {
  height: 0.3339rem;
  margin: 0.04281rem 0;
  width: 3.42466rem;
}
.skeleton_baseDesc .skeleton_baseDescActions {
  display: flex;
  gap: 0.08562rem;
}
.skeleton_baseDesc .skeleton_baseDescActions > span {
  height: 0.41096rem;
  flex: 1;
  max-width: 1.62671rem;
}