@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.refresh-icon {
  font-size: 0.25685rem;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  line-height: 1;
  background-color: transparent;
  padding: 0;
}
.refresh-icon:hover, .refresh-icon:focus-visible {
  color: rgba(255, 255, 255, 0.8);
}
.refresh-icon:active {
  color: rgba(240, 240, 240, 0.8);
}
.wine-red-dark .refresh-icon {
  color: var(--switch-color);
}
.wine-red-dark .refresh-icon:hover {
  color: var(--switch-color-hover);
}
.wine-red-dark .refresh-icon:focus-visible {
  color: var(--switch-color-focus);
}
.wine-red-dark .refresh-icon:active {
  color: var(--switch-color-active);
}
.wine-red-dark .refresh-icon.disabled {
  color: var(--switch-color-disabled);
  pointer-events: none;
}