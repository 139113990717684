@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.slider-container {
  position: relative;
  border-radius: var(--base-border-radius);
  overflow: hidden;
  outline-offset: -0.01712rem;
}