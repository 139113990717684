@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.skeleton_cloudGameCategory {
  display: flex;
  flex-wrap: wrap;
  gap: 0.11986rem;
  padding: 0.11986rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.skeleton_cloudGameCategory-item {
  height: 0.5137rem;
  border-radius: 0.30822rem;
  border: 0.00856rem solid #F6F2FF;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}