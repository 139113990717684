@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  display: flex;
  flex-direction: column;
  gap: 0.13699rem;
  line-height: 1.5;
  color: #fff;
  justify-content: flex-start;
}
.container .gameIcon {
  width: 1.35274rem;
  height: 1.35274rem;
  border-radius: 0.06849rem;
}
.container .gameDesc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.13699rem;
}
.container .gameDesc .title {
  font-size: 0.32534rem;
  margin: 0;
  line-height: 0.41952rem;
  font-weight: 500;
}
.container .gameDesc .title.doubleLineTitle {
  height: 0.85616rem;
  display: flex;
  align-items: center;
  width: 4.28082rem;
}
.container .gameDesc .describtion {
  font-size: 0.13699rem;
  display: flex;
  gap: 0.10274rem;
  align-items: center;
  line-height: 0.20548rem;
  height: 0.20548rem;
  margin-bottom: 0.06849rem;
}
.container .gameDesc .describtion .rate {
  margin-top: -0.01712rem;
}
.container .gameDesc .describtion .supportsContainer {
  display: flex;
  align-items: center;
  gap: 0.03425rem;
}
.container .gameDesc .describtion .supportsContainer .supportIcon {
  font-size: 0.11986rem;
  color: rgba(255, 255, 255, 0.9019607843);
  border: 0.00856rem solid rgba(255, 255, 255, 0.3);
  border-radius: 0.02568rem;
  padding: 0.01712rem 0.05137rem;
  line-height: 1;
  background: rgba(0, 0, 0, 0.2);
}
.container .gameDesc .describtion .split {
  width: 0.00428rem;
  height: 0.13699rem;
  background-color: currentColor;
  opacity: 0.4;
}