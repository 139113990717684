@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.gallery-container {
  cursor: pointer;
  height: 2.53425rem;
  width: auto;
  overflow: hidden;
  border-radius: 0.06849rem;
}
.gallery-container .imageImg,
.gallery-container .ember-iframe-container {
  transition: all 0.3s ease-in-out;
}
.gallery-container .defaultImge {
  background-size: 30% 30%;
}
.gallery-container:hover .imageImg,
.gallery-container:hover .ember-iframe-container {
  transform: scale(1.05);
}
.gallery-container .imageContainer img {
  width: auto;
  height: 100%;
}