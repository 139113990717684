@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.lightRcTooltip {
  opacity: 1;
}
.lightRcTooltip :global .rc-tooltip-inner {
  background-color: #fff;
  color: rgba(40, 48, 76, 0.6);
  padding: 0.10274rem 0.13699rem;
  border-radius: var(--base-border-radius);
  line-height: 1.4;
  font-size: 0.11986rem;
  box-shadow: 0rem 0.03425rem 0.13699rem 0.00856rem rgba(0, 0, 0, 0.5);
}
.lightRcTooltip:global(.rc-tooltip-placement-right) :global(.rc-tooltip-arrow), .lightRcTooltip:global(.rc-tooltip-placement-rightTop) :global(.rc-tooltip-arrow), .lightRcTooltip:global(.rc-tooltip-placement-rightBottom) :global(.rc-tooltip-arrow) {
  border-right-color: #fff;
}
.lightRcTooltip:global(.rc-tooltip-placement-left) :global(.rc-tooltip-arrow), .lightRcTooltip:global(.rc-tooltip-placement-leftTop) :global(.rc-tooltip-arrow), .lightRcTooltip:global(.rc-tooltip-placement-leftBottom) :global(.rc-tooltip-arrow) {
  border-left-color: #fff;
}
.lightRcTooltip:global(.rc-tooltip-placement-top) :global(.rc-tooltip-arrow), .lightRcTooltip:global(.rc-tooltip-placement-topLeft) :global(.rc-tooltip-arrow), .lightRcTooltip:global(.rc-tooltip-placement-topRight) :global(.rc-tooltip-arrow) {
  border-top-color: #fff;
}
.lightRcTooltip:global(.rc-tooltip-placement-bottom) :global(.rc-tooltip-arrow), .lightRcTooltip:global(.rc-tooltip-placement-bottomLeft) :global(.rc-tooltip-arrow), .lightRcTooltip:global(.rc-tooltip-placement-bottomRight) :global(.rc-tooltip-arrow) {
  border-bottom-color: #fff;
}