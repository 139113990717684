@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.bannerOne-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.96918rem;
}
.bannerOne-container .bannerOne-header {
  margin-bottom: 0.13699rem;
  display: flex;
  gap: 0.13699rem;
}
.bannerOne-container .bannerOne-header .image {
  width: 0.47945rem;
  height: 0.47945rem;
  position: relative;
}
.bannerOne-container .bannerOne-header .desc {
  display: flex;
  gap: 0.06849rem;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.bannerOne-container .bannerOne-header .desc .rate-wrap {
  height: 0.1113rem;
  line-height: 0.1113rem;
  font-size: 0.1113rem;
}
.bannerOne-container .bannerOne-header .app-info {
  height: 0.17979rem;
  text-transform: uppercase;
  font-size: 0.11986rem;
  opacity: 0.7;
  display: flex;
  gap: 0.10274rem;
  align-items: center;
}
.bannerOne-container .bannerOne-header .app-info .split {
  width: 0.01712rem;
  height: 0.10274rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.bannerOne-container .bannerOne-title {
  font-size: 0.32534rem;
  line-height: 0.41952rem;
  font-weight: 500;
  margin-bottom: 0.20548rem;
}
.bannerOne-container .bannerOne-skeleton {
  padding: 0;
}