@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.msiBanner-container {
  height: 2.96062rem;
  text-align: center;
  margin-top: 0.44521rem;
  overflow: hidden;
  margin-bottom: 0.06849rem;
}
.msiBanner-container .isCenter {
  transition-delay: 0.3s;
  border: 0.00856rem solid var(--primary-color);
}