@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  width: 100%;
  overflow: hidden;
  color: #1b1e38;
  display: flex;
  flex-direction: column;
}
.container .header,
.container .contentItem {
  display: flex;
  justify-content: space-between;
  gap: 0.04281rem;
  padding: 0.06849rem 0.27397rem;
  font-size: 0.13699rem;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.container .header .clearIcon {
  font-size: 0.13699rem;
}
.container .header .clearText {
  color: #7f82a0;
  font-size: 0.11986rem;
  cursor: pointer;
}
.container .content {
  max-height: 2.99658rem;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0.17979rem;
}
.container .contentItem {
  cursor: pointer;
  position: relative;
}
.container .contentItem .historyText {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.container .contentItem .deleteIcon {
  position: absolute;
  top: 50%;
  right: 0.18836rem;
  transform: translateY(-50%);
  font-size: 0.09418rem;
  padding: 0.08562rem;
  background-color: transparent;
  color: currentColor;
}
.container .contentItem:hover, .container .contentItem:focus-within {
  background-color: rgba(0, 0, 0, 0.05);
}
.container .contentItem:hover .deleteIcon, .container .contentItem:focus-within .deleteIcon {
  color: #db001c;
}