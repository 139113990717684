@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.skeleton_appDetailBanner {
  margin-top: 1.11301rem;
  margin-bottom: 0.17979rem;
}
.skeleton_appDetailBanner .skeleton_appDetailBannerInner .skeleton_appBannerActions > span {
  height: 0.46233rem;
}