@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  position: relative;
  width: auto !important;
  border-radius: 0.31678rem;
  border: 0.00856rem solid rgba(255, 255, 255, 0.2);
  background: rgb(255, 255, 255);
  box-shadow: 0rem 0.13699rem 0.13699rem 0rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.container .inputContainer {
  position: relative;
  color: #1f2333;
  cursor: pointer;
}
.container .inputContainer .icon {
  line-height: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.container .inputContainer .icon.beforeIcon {
  left: 0.27397rem;
  font-size: 0.30822rem;
}
.container .inputContainer .icon.afterIcon {
  right: 0.2911rem;
  font-size: 0.17979rem;
  cursor: pointer;
}
.container .inputContainer .icon.clearIcon {
  right: 0.2911rem;
  font-size: 0.11986rem;
  color: #232642;
  cursor: pointer;
  z-index: 2;
}
.container .inputContainer:hover .clearIcon {
  color: #db001c;
}
.container .inputContainer .input {
  z-index: 1;
  width: 100%;
  padding: 0.17979rem 0.71918rem;
  font-size: 0.17123rem;
  border-radius: 0.06849rem;
  line-height: 1.4;
  background: transparent;
  color: #1f2333;
  position: relative;
  outline: none;
}
.container .inputContainer .input:focus::-webkit-input-placeholder {
  color: transparent;
}
.container .inputContainer .input::-webkit-input-placeholder {
  color: #1f2333;
  transition: color 0.2s ease-in-out;
}