@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.listLayout-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.listLayout-container .listLayout-header {
  position: relative;
}
.listLayout-container .listLayout-content {
  overflow: hidden;
  overflow-y: scroll;
  min-height: 0;
  flex: 1;
  padding-top: 0.02568rem;
}
.listLayout-container .listLayout-content .listLayout-contentInner {
  display: flex;
  flex-direction: column;
  gap: 0.20548rem;
}
.listLayout-container .listLayout-content .listLayout-prefix,
.listLayout-container .listLayout-content .listLayout-suffix {
  font-size: 0.13699rem;
}