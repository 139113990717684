@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.action-button-container.action-button-combo {
  gap: 0.04281rem;
  width: max-content;
}
.action-button-container.action-button-combo .button:first-of-type:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.action-button-container.action-button-combo .button:last-of-type:not(:first-of-type) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}