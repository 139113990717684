@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.refreshButton {
  min-width: 1.19863rem;
  font-size: 0.13699rem;
  margin-top: 0.17123rem;
  text-transform: uppercase;
}