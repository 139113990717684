@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.cloudGame-container {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
}
.cloudGame-container .game-carousel-section-headerRight .button {
  background-color: transparent;
}
.cloudGame-container .game-carousel-section-headerRight .button::before, .cloudGame-container .game-carousel-section-headerRight .button::after {
  border-radius: 0.25685rem;
}

.cloudGame-empty {
  padding-top: 0.77055rem;
}