@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.skeleton_appDetail {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
  overflow-y: auto;
}
.skeleton_appDetail .skeleton_appDetail_item {
  height: 2.53425rem;
}
.skeleton_appDetail .skeleton_appDetail_item .defaultImge {
  background-size: 30% 30%;
}