@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  font-size: 0.13699rem;
  color: #fff;
}
.container .content .subHeader {
  font-size: 0.15411rem;
  margin-bottom: 0.20548rem;
  line-height: 0.21404rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9019607843);
}
.container .content .description {
  height: 1.0274rem;
  transition: height 0.2s ease-in-out;
  overflow: hidden;
  font-size: 0.13699rem;
  color: rgba(255, 255, 255, 0.8);
}
.container .content .description p {
  line-height: 1.5;
}
.container .content .description h1 {
  font-size: 0.14555rem;
  font-weight: 400;
  margin: 0;
}
.container .content .description h2 {
  font-size: 0.14555rem;
  font-weight: 400;
  margin: 0;
}
.container .content .showMore {
  display: inline-flex;
  align-items: center;
  gap: 0.08562rem;
  font-size: 0.13699rem;
  transition: color 0.2s ease-in-out;
  margin-top: 0.20548rem;
  cursor: pointer;
  background-color: transparent;
  color: var(--link-color);
}
.container .content .showMore:hover {
  color: var(--link-color-hover);
}
.container .content .showMore:focus-visible {
  color: var(--link-color-focus);
}
.container .content .showMore:active {
  color: var(--link-color-active);
}
.container .content .showMore.disabled {
  color: var(--link-color-disabled);
  pointer-events: none;
}
.container .content .showMore .toggleIcon {
  transition: transform 0.3s ease-in-out;
  font-size: 0.20548rem;
}
.container .content .showMore .rotationIcon {
  transform: rotate(180deg);
  transform-origin: center center;
}