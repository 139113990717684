@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container .footer .title {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  text-shadow: 0rem 0.01712rem 0.06849rem rgba(0, 0, 0, 0.8);
  line-height: 0.18836rem;
  font-size: 0.13699rem;
  height: 0.18836rem;
  color: var(--secondary-text-color);
}
.container .footer .subTitle {
  margin-top: 0.06849rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--sub-text-color);
}
.container .footer .subTitle .category {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.11986rem;
}
.container .footer .subTitle .gameType {
  display: flex;
  align-items: center;
  gap: 0.02568rem;
}
.container .footer .subTitle .gameType .typeIcon {
  padding: 0.03425rem;
  font-size: 0.1113rem;
  line-height: 1;
  border-radius: var(--base-border-radius);
}
.container .transitionImage img {
  transition: transform 0.5s ease-in-out;
}
.container:hover .transitionImage img {
  transform: scale(1.2);
  transform-origin: center center;
}
.container:hover :global(.installed) {
  opacity: 0.6;
}

.overlayContainer {
  position: relative;
  overflow: hidden;
  border-radius: var(--base-border-radius);
  margin-bottom: 0.10274rem;
  outline-offset: 0rem;
}
.overlayContainer.slideUp .buttonOverlay {
  visibility: hidden;
  transform: translateY(102%);
  transition: transform 0.3s ease-in-out;
}
.overlayContainer.slideUp:hover .buttonOverlay, .overlayContainer.slideUp:focus-within .buttonOverlay, .overlayContainer.slideUp.isActive .buttonOverlay {
  visibility: visible;
  transform: translateY(0%);
}
.overlayContainer.slideUp:hover .buttonOverlay .detailClickPanel, .overlayContainer.slideUp:focus-within .buttonOverlay .detailClickPanel, .overlayContainer.slideUp.isActive .buttonOverlay .detailClickPanel {
  opacity: 1;
}
.overlayContainer.slideUp:focus-within .buttonOverlay {
  transition: none;
}
.overlayContainer .buttonOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}
.overlayContainer .buttonOverlay .buttonWrap {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0.03425rem;
  justify-content: flex-end;
}
.overlayContainer .buttonOverlay .buttonWrap .detailClickPanel {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.17123rem;
  color: var(--link-color);
  background: radial-gradient(32.98% 44.7% at 50% 50%, rgba(0, 0, 0, 0.54) 0%, rgba(0, 0, 0, 0) 100%);
  cursor: pointer;
  transition: opacity 0.3s ease-in-out 0.2s;
  opacity: 0;
  margin-bottom: -0.03425rem;
}
.overlayContainer .buttonOverlay .buttonWrap .detailClickPanel:hover {
  color: var(--link-color-hover);
}
.overlayContainer .buttonOverlay .buttonWrap .detailClickPanel:focus-visible {
  color: var(--link-color-focus);
}
.overlayContainer .buttonOverlay .buttonWrap .detailClickPanel:active {
  color: var(--link-color-active);
}
.overlayContainer .buttonOverlay .buttonWrap .detailClickPanel.disabled {
  color: var(--link-color-disabled);
  pointer-events: none;
}
.overlayContainer .buttonOverlay .buttonWrap .itemImgButton {
  text-align: center;
  height: 23%;
  max-height: 0.5137rem;
  min-height: 0.30822rem;
  border-radius: 0;
  display: block;
  line-height: 1;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  font-size: 0.1113rem;
  min-width: unset;
  opacity: 0.95;
  padding: 0;
}
.overlayContainer .leftBottomContainer,
.overlayContainer .rightTopContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-size: 0.13699rem;
  gap: 0.03425rem;
  padding: 0.04281rem;
  z-index: 2;
}
.overlayContainer .rightTopContainer {
  left: unset;
  bottom: unset;
}
.overlayContainer .leftBottomContainer {
  top: unset;
  right: unset;
}