@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  margin-left: var(--screen-left);
}
.container .content {
  height: calc(100% - 1.28425rem);
  overflow: auto;
  margin-top: 1.28425rem;
}
.container .searchContainer {
  text-align: center;
  width: 5.47945rem;
  position: fixed;
  left: calc(50% - 0.21404rem);
  transform: translateX(-50%);
  z-index: 10;
  margin-top: 0.24829rem;
}
.container .recentSearches {
  padding: 0 0.12842rem;
  margin-bottom: 0.34247rem;
}
.container .recommendedForYou :global(.heading) {
  font-size: 0.17123rem;
  padding: 0.23116rem 0;
}
.container .recommendedForYou :global(.font18) {
  font-size: 0.13699rem;
}