@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.pageLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pageLayout .pageLayoutHeader .pageLayoutHeaderTitle {
  display: flex;
  gap: 0.08562rem;
  align-items: center;
  font-size: 0.15411rem;
  cursor: pointer;
  background-color: transparent;
  color: currentColor;
  line-height: inherit;
}
.pageLayout .pageLayoutHeader .pageLayoutHeaderTitle .headerBackIcon {
  transition: all 0.2s ease-in-out;
  color: var(--warning-color);
  font-size: 0.23973rem;
}
.pageLayout .pageLayoutHeader .pageLayoutHeaderTitle:hover .headerBackIcon {
  transform: scale(1.1);
}
.pageLayout .pageLayoutContent {
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  overscroll-behavior: none;
}
.pageLayout .pageLayoutContent > div:last-child {
  padding-bottom: 0.25685rem;
}