@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.button.borderGradientButton::before, .button.borderGradientButton::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 0.02568rem;
  overflow: hidden;
}
.button.borderGradientButton::before {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #eb2c43, #3541e1);
}
.button.borderGradientButton::after {
  background-color: #21242c;
  top: 0.01712rem;
  left: 0.01712rem;
  right: 0.01712rem;
  bottom: 0.01712rem;
}
.button.borderGradientButton:hover::after {
  background-color: #373941;
}
.button.borderGradientButton:focus::after, .button.borderGradientButton:active::after {
  background-color: #1e2028;
}