@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.advertisement-container {
  position: relative;
  background-size: cover;
  cursor: default;
  border-radius: var(--medium-border-radius);
  background-position-x: 20%;
  background-position-y: top;
  background-repeat: no-repeat;
}
.advertisement-container .advertisement-containerInner {
  display: flex;
  gap: 0.27397rem;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.37671rem;
  padding-top: 0.82192rem;
}
.advertisement-container .advertisement-containerInner .advertisement-bannerAction {
  margin-top: 0.17123rem;
}
.advertisement-container .advertisement-appDetails {
  max-width: 3.42466rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.03425rem;
}
.advertisement-container .advertisement-appDetails .advertisement-availableText {
  font-size: 0.13699rem;
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
  line-height: 0.20548rem;
}
.advertisement-container .advertisement-appDetails .advertisement-appName {
  font-size: 0.23973rem;
  font-weight: 500;
  line-height: 0.34247rem;
  color: #fff;
}
.advertisement-container .advertisement-appDetails .advertisement-platformName {
  font-size: 0.17123rem;
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
}
.advertisement-container .advertisement-smallIconContainer {
  width: 1.71233rem;
  height: 1.71233rem;
  border-radius: var(--base-border-radius);
  flex-shrink: 0;
  cursor: pointer;
}
.advertisement-container .advertisement-smallIconContainer:hover img {
  transform: scale(1.1);
}
.advertisement-container .advertisement-smallIconContainer img {
  transition: transform 0.3s ease-in-out;
  width: 100%;
}
.advertisement-container .advertisement-smallIconContainer:hover .installed {
  opacity: 0.6;
}
.advertisement-container .advertisement-smallIconContainer:hover .advertisement-detailClickPanel {
  opacity: 1;
}
.advertisement-container .advertisement-smallIconContainer .advertisement-detailClickPanel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.30822rem;
  color: var(--link-color);
  background: radial-gradient(rgba(0, 0, 0, 0.9019607843), rgba(255, 255, 255, 0) 0.77055rem, rgba(255, 255, 255, 0) 100%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.advertisement-container .advertisement-smallIconContainer .advertisement-detailClickPanel:hover {
  color: var(--link-color-hover);
}
.advertisement-container .advertisement-smallIconContainer .advertisement-detailClickPanel:focus-visible {
  color: var(--link-color-focus);
}
.advertisement-container .advertisement-smallIconContainer .advertisement-detailClickPanel:active {
  color: var(--link-color-active);
}
.advertisement-container .advertisement-smallIconContainer .advertisement-detailClickPanel.disabled {
  color: var(--link-color-disabled);
  pointer-events: none;
}
.wine-red-dark .advertisement-container .advertisement-smallIconContainer .advertisement-detailClickPanel .svgIcon {
  color: #fff;
}