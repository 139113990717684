@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.skeleton_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.34247rem 0 0.13699rem 0;
  line-height: 0.42808rem;
}
.skeleton_header .skeleton_headerLeft,
.skeleton_header .skeleton_headerRight {
  display: flex;
  align-items: center;
}