@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #252a3e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  border-radius: var(--medium-border-radius);
  color: var(--secondary-text-color);
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: column;
  padding: 0.59932rem 0.58219rem 0.64212rem;
  text-align: center;
  gap: 0.34247rem;
  width: 4.28082rem;
}
.content .body {
  display: flex;
  flex-direction: column;
}
.content .body .haeder {
  display: flex;
  gap: 0.21404rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.35959rem;
}
.content .body .haeder img {
  width: 0.5137rem;
  height: 0.5137rem;
}
.content .body .haeder .icon {
  color: #b0aebf;
  margin-top: 0.08562rem;
}
.content .body .title {
  font-weight: bold;
  font-size: 0.18836rem;
  margin-bottom: 0.18836rem;
  color: #fff;
}
.content .footer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.content .footer .loginExpired {
  font-size: 0.13699rem;
  color: #f4cf00;
}
.content .footer .button {
  width: 1.71233rem;
  padding: 0.16267rem 0.50514rem 0.15411rem 0.49658rem;
  border-radius: 0.06849rem;
}