@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  margin-left: 0.42808rem;
}
.container .headerContentLeft {
  display: flex;
  gap: 0.10274rem;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  color: currentColor;
  line-height: inherit;
  transition: all 0.2s ease-in-out;
}
.container .headerContentLeft .headerGameIcon {
  width: 0.34247rem;
  height: 0.34247rem;
  border-radius: 0.06849rem;
  overflow: hidden;
  position: relative;
}
.container .headerContentLeft .headerBackIcon {
  font-size: 0.23973rem;
  color: var(--link-color);
}
.container .headerContentLeft .headerBackIcon:hover {
  color: var(--link-color-hover);
}
.container .headerContentLeft .headerBackIcon:focus-visible {
  color: var(--link-color-focus);
}
.container .headerContentLeft .headerBackIcon:active {
  color: var(--link-color-active);
}
.container .headerContentLeft .headerBackIcon.disabled {
  color: var(--link-color-disabled);
  pointer-events: none;
}
.container .headerContentLeft:hover {
  transform: translateX(-0.01712rem);
}
.container .actionButton {
  padding-right: 0.77055rem;
}
.container .contentWrap .banner {
  margin-top: 1.11301rem;
  margin-bottom: 0.17979rem;
}
.container .contentWrap .screenshots .screenshotsGallery {
  width: 1.83219rem;
  overflow: hidden;
}
.container .contentWrap .screenshots .screenshotsGallery_1 {
  width: 4.19521rem;
}
.container .contentWrap .screenshots .screenshotsGallery_1.screenshotsGallery_video {
  width: 4.19521rem !important;
}
.container .contentWrap .screenshots .screenshotsTitle {
  padding-right: 0.25685rem;
}
.container .contentWrap .screenshots :global(.swiper-slide) {
  width: auto;
}
.container .contentWrap .screenshots .galleryMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}
.container .contentWrap .screenshots .galleryMask:hover ~ div {
  transform: scale(1.1);
}
.container .contentWrap .describtion {
  margin-right: 0.77055rem;
}
.container .contentWrap .gameFeature {
  margin-right: 0.77055rem;
}
.container .contentWrap .moreStrategyGames {
  margin-right: 0.77055rem;
}
.container .contentWrap .similarGames {
  margin-right: 0.77055rem;
}
.container .actionButton :global .button.largeButton {
  padding: 0.12842rem 0.17123rem;
}
.container .actionButton :global .button.largeButton .buttonIcon {
  margin-right: 0.08562rem;
  font-size: 0.20548rem;
}
.container .actionButton :global .button.smallButton {
  padding: 0.07705rem 0.10274rem;
}
.container .actionButton :global .button.smallButton .buttonIcon {
  margin-right: 0.05137rem;
  font-size: 0.13699rem;
}