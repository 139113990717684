@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.skeleton_gameItem .skeleton_gameItemTitle {
  width: 80%;
  margin-top: 0.10274rem;
  height: 0.18836rem;
}
.skeleton_gameItem .skeleton_gameItemSubTitle {
  margin-top: 0.06849rem;
  width: 50%;
  height: 0.15411rem;
}