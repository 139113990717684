@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.skeleton_largeCarousel {
  margin-top: 0.5137rem;
  margin-bottom: 0.25685rem;
  width: 100%;
}