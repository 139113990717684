@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.banner {
  width: 100%;
  position: relative;
  height: 100%;
}
.banner .banner-slide {
  width: 100%;
  height: 100%;
  perspective: 4.28082rem;
}
.banner .banner-slide li {
  list-style-type: none;
  position: absolute;
  top: 0;
  width: 60%;
  transition: all 0.3s ease-in-out;
  transform-origin: center;
  transform-style: preserve-3d;
  border-radius: var(--base-border-radius);
  overflow: hidden;
}
.banner .banner-slide li.b-left, .banner .banner-slide li.b-right {
  z-index: 10;
  opacity: 0.8;
}
.banner .banner-slide li.b-left::after, .banner .banner-slide li.b-right::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
.banner .banner-slide li.b-left {
  left: 0;
}
.banner .banner-slide li.b-left::after {
  background-image: linear-gradient(90deg, #000 0%, #222 50%, #222 100%);
}
.banner .banner-slide li.b-center {
  z-index: 100;
  cursor: pointer;
  left: 20%;
}
.banner .banner-slide li.b-right {
  left: 40%;
}
.banner .banner-slide li.b-right::after {
  background-image: linear-gradient(-90deg, #000 0%, #222 50%, #222 100%);
}
.banner .banner-slide li.b-wait {
  opacity: 0.4;
  transform: translate3d(0, 0, -0.68493rem);
  left: 20%;
  z-index: 10;
}
.banner .banner-navigation-left, .banner .banner-navigation-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.77055rem;
  z-index: 101;
  color: var(--switch-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
}
.banner .banner-navigation-left:hover, .banner .banner-navigation-right:hover {
  color: var(--switch-color-hover);
}
.banner .banner-navigation-left:focus-visible, .banner .banner-navigation-right:focus-visible {
  color: var(--switch-color-focus);
}
.banner .banner-navigation-left:active, .banner .banner-navigation-right:active {
  color: var(--switch-color-active);
}
.banner .banner-navigation-left.disabled, .banner .banner-navigation-right.disabled {
  color: var(--switch-color-disabled);
  pointer-events: none;
}
.banner .banner-navigation-left {
  left: 0.5137rem;
  transform-origin: center;
  transform: translateY(-50%) rotate(180deg);
}
.banner .banner-navigation-right {
  right: 0.5137rem;
}