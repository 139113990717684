@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.banner-swiper-switch-container {
  z-index: 10;
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(50% + 0.21404rem);
  transform: translateY(-50%);
  transition: opacity 0.3s ease-in-out;
}
.banner-swiper-switch-container .switchLeft,
.banner-swiper-switch-container .switchRight {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
  bottom: 0.18836rem;
}
.banner-swiper-switch-container .switchLeft .switchIcon,
.banner-swiper-switch-container .switchRight .switchIcon {
  font-size: 0.27397rem;
  border-radius: 50%;
  padding: 0.08562rem;
  cursor: pointer;
  background-color: var(--switch-bg-color);
  color: var(--switch-color);
}
.banner-swiper-switch-container .switchLeft .switchIcon:hover,
.banner-swiper-switch-container .switchRight .switchIcon:hover {
  background-color: var(--switch-bg-color-hover);
}
.banner-swiper-switch-container .switchLeft .switchIcon:focus-visible,
.banner-swiper-switch-container .switchRight .switchIcon:focus-visible {
  background-color: var(--switch-bg-color-focus);
}
.banner-swiper-switch-container .switchLeft .switchIcon:active,
.banner-swiper-switch-container .switchRight .switchIcon:active {
  background-color: var(--switch-bg-color-active);
}
.banner-swiper-switch-container .switchLeft .switchIcon.disabled,
.banner-swiper-switch-container .switchRight .switchIcon.disabled {
  background-color: var(--switch-bg-color-disabled);
  pointer-events: none;
}
.banner-swiper-switch-container .switchLeft .switchIcon:hover,
.banner-swiper-switch-container .switchRight .switchIcon:hover {
  color: var(--switch-color-hover);
}
.banner-swiper-switch-container .switchLeft .switchIcon:focus-visible,
.banner-swiper-switch-container .switchRight .switchIcon:focus-visible {
  color: var(--switch-color-focus);
}
.banner-swiper-switch-container .switchLeft .switchIcon:active,
.banner-swiper-switch-container .switchRight .switchIcon:active {
  color: var(--switch-color-active);
}
.banner-swiper-switch-container .switchLeft .switchIcon.disabled,
.banner-swiper-switch-container .switchRight .switchIcon.disabled {
  color: var(--switch-color-disabled);
  pointer-events: none;
}
.banner-swiper-switch-container .switchLeft .switchIcon.disabled,
.banner-swiper-switch-container .switchRight .switchIcon.disabled {
  display: none;
}
.banner-swiper-switch-container .switchLeft {
  left: 0;
  transform: translateX(-50%);
}
.banner-swiper-switch-container .switchRight {
  right: 0;
  transform: translateX(50%);
}