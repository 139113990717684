@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  font-size: 0.11986rem;
  height: 0.5137rem;
  line-height: 1.3;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  text-align: center;
  flex: 1;
  transition: all 0.1s ease-in-out;
  background-color: #2b3046;
  padding: 0.08562rem;
  border-radius: var(--base-border-radius);
  width: 100%;
}
.container:active {
  text-shadow: 0rem 0.02568rem 0.08562rem rgba(31, 35, 51, 0.6);
}