@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.carousel-layout {
  position: relative;
}
.carousel-layout .carousel-layout--content {
  padding: 0.02568rem;
  overflow: hidden;
}