@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.wine-red-dark .gameListItem-container .pinkChangeButton {
  opacity: 1 !important;
  background-color: #abafba;
  color: #2e3038 !important;
}
.wine-red-dark .gameListItem-container .pinkChangeButton:hover {
  background-color: #8f94a3;
}
.wine-red-dark .gameListItem-container .pinkChangeButton:focus-visible {
  background-color: #8f94a3;
}
.wine-red-dark .gameListItem-container .pinkChangeButton:active {
  background-color: #5c6170;
}
.wine-red-dark .gameListItem-container .pinkChangeButton.disabled {
  background-color: #5c6170;
  pointer-events: none;
}
.wine-red-dark .gameListItem-container .svgIcon {
  color: #fff;
}