@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.svgIcon {
  width: 1em;
  height: 1em;
  overflow: hidden;
  display: inline-table;
  vertical-align: middle;
  fill: currentColor;
}