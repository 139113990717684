@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.section-carousel-container {
  border-radius: var(--medium-border-radius);
  overflow: hidden;
  position: relative;
}
.section-carousel-container .section-carousel-header {
  position: absolute;
  z-index: 100;
  left: 0.37671rem;
  right: 0.54795rem;
  top: 0.37671rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.section-carousel-container .section-carousel-header .section-carousel-headerTitle {
  font-size: 0.23973rem;
  line-height: 0.34247rem;
  color: #fff;
}
.wine-red-dark .section-carousel-container .section-carousel-header .swiper-switch-container .swiper-switch-icon {
  color: #fff;
}
.wine-red-dark .section-carousel-container .section-carousel-header .swiper-switch-container .swiper-switch-icon:hover {
  color: var(--switch-color-hover);
}
.wine-red-dark .section-carousel-container .section-carousel-header .swiper-switch-container .swiper-switch-icon:focus-visible {
  color: var(--switch-color-focus);
}
.wine-red-dark .section-carousel-container .section-carousel-header .swiper-switch-container .swiper-switch-icon:active {
  color: var(--switch-color-active);
}
.wine-red-dark .section-carousel-container .section-carousel-header .swiper-switch-container .swiper-switch-icon.disabled {
  color: var(--switch-color-disabled);
  pointer-events: none;
}
.section-carousel-container .section-carousel-item {
  height: 3.08219rem;
}