@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5137rem 0 0.25685rem;
}
.container .containerInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  font-size: 0.13699rem;
}
.container .srcElement {
  width: 1.07329rem;
  height: 1.25539rem;
  margin-bottom: 0.17123rem;
}
.container .title {
  color: #fff;
  font-weight: 600;
  font-size: 0.2226rem;
}
.container .label {
  color: var(--secondary-text-color);
  margin-top: 0.17123rem;
  font-size: 0.12842rem;
  opacity: 0.6;
  width: 2.99658rem;
}