@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.swiper-switch-container {
  display: flex;
  align-items: center;
  gap: 0.14555rem;
  font-size: 0.11986rem;
}
.swiper-switch-container .swiper-switch-icon {
  min-width: 2em;
  min-height: 2em;
  outline-offset: 0rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--switch-bg-color);
  color: var(--switch-color);
  transition: all 0.2s ease-in-out;
}
.swiper-switch-container .swiper-switch-icon:not(.disabled) {
  cursor: pointer;
}
.swiper-switch-container .swiper-switch-icon:not(.disabled) {
  transition: all 0.2s ease-in-out;
  transform-origin: center center;
}
.swiper-switch-container .swiper-switch-icon:not(.disabled):hover {
  transform: scale(1.07);
}
.swiper-switch-container .swiper-switch-icon:hover {
  background-color: var(--switch-bg-color-hover);
}
.swiper-switch-container .swiper-switch-icon:focus-visible {
  background-color: var(--switch-bg-color-focus);
}
.swiper-switch-container .swiper-switch-icon:active {
  background-color: var(--switch-bg-color-active);
}
.swiper-switch-container .swiper-switch-icon.disabled {
  background-color: var(--switch-bg-color-disabled);
  pointer-events: none;
}
.swiper-switch-container .swiper-switch-icon:hover {
  color: var(--switch-color-hover);
}
.swiper-switch-container .swiper-switch-icon:focus-visible {
  color: var(--switch-color-focus);
}
.swiper-switch-container .swiper-switch-icon:active {
  color: var(--switch-color-active);
}
.swiper-switch-container .swiper-switch-icon.disabled {
  color: var(--switch-color-disabled);
  pointer-events: none;
}