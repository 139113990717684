@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.game-carousel-section.game-carousel-section-homeBanner {
  position: relative;
}
.game-carousel-section.game-carousel-section-homeBanner:hover .game-carousel-section-homeBannerSwitch {
  opacity: 1;
}
.game-carousel-section.game-carousel-section-homeBanner .game-carousel-section-homeBannerSwitch {
  opacity: 0;
}
.game-carousel-section.game-carousel-section-homeBanner .game-carousel-section-carouselItem {
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.game-carousel-section.game-carousel-section-homeBanner .game-carousel-section-carouselItem::after, .game-carousel-section.game-carousel-section-homeBanner .game-carousel-section-carouselItem::before {
  display: block;
  content: "";
  height: 0.17123rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
  transform: translateY(0.17123rem);
}
.game-carousel-section.game-carousel-section-homeBanner .game-carousel-section-activeItem {
  transform: translateY(-0.17123rem);
  position: relative;
}
.game-carousel-section.game-carousel-section-homeBanner .game-carousel-section-activeItem .gameItemOverlayContainer {
  box-shadow: 0rem 0.03425rem 0.19692rem 0 var(--box-shadow);
}
.game-carousel-section.game-carousel-section-homeBanner .game-carousel-section-activeItem .gameItemOverlayContainer::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0rem 0 0 0.03425rem var(--link-color);
  border-radius: var(--base-border-radius);
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
}
.wine-red-dark .game-carousel-section.game-carousel-section-homeBanner .game-carousel-section-activeItem .gameItemOverlayContainer::after {
  box-shadow: inset 0rem 0 0 0.0214rem var(--link-color);
}
.game-carousel-section.game-carousel-section-homeBanner .game-carousel-section-activeItem::after {
  position: absolute;
  border: 0.03425rem solid var(--link-color);
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.game-carousel-section .game-carousel-section-headerLeft,
.game-carousel-section .game-carousel-section-headerRight {
  display: flex;
  align-items: center;
  gap: 0.13699rem;
}
.game-carousel-section .game-carousel-section-headerLeft .game-carousel-section-title,
.game-carousel-section .game-carousel-section-headerRight .game-carousel-section-title {
  font-size: inherit;
  font-weight: inherit;
}