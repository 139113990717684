@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.skeleton_collect {
  margin-bottom: 0.25685rem;
}
.skeleton_collect .skeleton_colectionItem {
  height: 0.5137rem;
  border-radius: var(--base-border-radius);
  min-width: 0.68493rem;
}