@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.home-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
}