@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  color: #fff;
}
.container .content {
  font-size: 0.11986rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.77055rem;
}
.container .contentLeft {
  min-width: 1.71233rem;
  display: flex;
  flex-direction: column;
  gap: 0.17123rem;
}
.container .contentLeft .tabContainer {
  text-align: right;
}
.container .contentLeft .tabContainer .tabHeader {
  font-size: 0.15411rem;
  margin-bottom: 0.20548rem;
  color: rgba(255, 255, 255, 0.9019607843);
}
.container .contentLeft .tabContainer .tabContent {
  padding-bottom: 0.10274rem;
  line-height: 1.375;
  font-size: 0.15411rem;
  display: flex;
  flex-direction: column;
  gap: 0.13699rem;
  padding-right: 0.21404rem;
  align-items: flex-end;
  position: relative;
}
.container .contentLeft .tabContainer .tabContent::after {
  content: "";
  width: 0.00856rem;
  height: 100%;
  display: block;
  background-color: var(--link-color);
  opacity: 0.2;
  position: absolute;
  right: -0.01284rem;
  top: 0;
  bottom: 0;
}
.container .contentLeft .tabContainer .tabContent .tabContentItem {
  position: relative;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  font-weight: normal;
  font-size: 0.13699rem;
  color: rgba(255, 255, 255, 0.6);
}
.container .contentLeft .tabContainer .tabContent .tabContentItem:hover {
  color: var(--link-color-hover);
}
.container .contentLeft .tabContainer .tabContent .tabContentItem::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 0.01712rem;
  background-color: transparent;
  right: -0.23116rem;
  transition: background-color 0.2s ease-in-out;
}
.container .contentLeft .tabContainer .tabContent .tabContentItemActive {
  color: var(--link-color);
  opacity: 1;
}
.container .contentLeft .tabContainer .tabContent .tabContentItemActive:hover {
  color: var(--link-color-hover);
}
.container .contentLeft .tabContainer .tabContent .tabContentItemActive:focus-visible {
  color: var(--link-color-focus);
}
.container .contentLeft .tabContainer .tabContent .tabContentItemActive:active {
  color: var(--link-color-active);
}
.container .contentLeft .tabContainer .tabContent .tabContentItemActive.disabled {
  color: var(--link-color-disabled);
  pointer-events: none;
}
.container .contentLeft .tabContainer .tabContent .tabContentItemActive::after {
  background-color: var(--link-color);
}
.container .contentRight {
  flex: 1;
  position: relative;
  min-width: 0;
  border-radius: 0.05137rem;
  overflow: hidden;
}
.container .contentRight .swiper-wrapper {
  height: auto !important;
}
.container .contentRight .activeContainer {
  max-width: 8.76712rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.container .contentRight .activeContainer img,
.container .contentRight .activeContainer video {
  width: 100%;
}