@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  width: 2.20034rem;
  height: 0.5137rem;
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0rem 0.06849rem 0.10274rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 0.27397rem 0.27397rem 0.27397rem 0.27397rem;
  backdrop-filter: blur(0.08562rem);
  opacity: 1;
  color: #1f2333;
  font-size: 0.13699rem;
  font-weight: bold;
  cursor: pointer;
  border: 0.00856rem solid rgba(255, 255, 255, 0.6);
}
.container::before, .container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.27397rem 0.27397rem 0.27397rem 0.27397rem;
}
.container::before {
  background: linear-gradient(332deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.3) 100%);
  z-index: -1;
}
.container::after {
  background: linear-gradient(340deg, #ffffff 0%, #a94b6a 53%, #ffffff 100%);
  opacity: 0.4;
  z-index: -2;
}
.container .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.08562rem;
}
.container .inner img {
  width: 0.27397rem;
  height: 0.27397rem;
}
.container .inner span {
  font-size: 0.13699rem;
  height: 0.20548rem;
  line-height: 0.20548rem;
  font-weight: bold;
  letter-spacing: -0.00642rem;
}