@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.skeleton_gameList {
  display: flex;
  flex-direction: column;
}
.skeleton_gameList .skeleton_gameListHeader {
  position: relative;
}
.skeleton_gameList .skeleton_gameListContent {
  min-height: 0;
  flex: 1;
}
.skeleton_gameList .skeleton_gameListContent .skeleton_gameListContentInner {
  display: grid;
  grid-template-rows: max-content;
}