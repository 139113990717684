@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  display: flex;
  gap: 0.10274rem;
  padding: 0.06849rem 0.1113rem 0.06849rem 0.27397rem;
  font-size: 0.11986rem;
  color: #1b1e38;
}
.container:hover, .container:focus, .container:focus-within {
  background-color: rgba(0, 0, 0, 0.0509803922);
}
.container:hover .right .actions, .container:focus .right .actions, .container:focus-within .right .actions {
  display: flex;
}
.container .left {
  width: 0.30822rem;
  height: 0.30822rem;
  flex-shrink: 0;
}
.container .right {
  display: flex;
  gap: 0.21404rem;
  flex: 1;
  min-width: 0;
}
.container .right .title {
  padding: 0.08562rem 0;
  min-width: 0;
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  text-align: left;
}
.container .right .actions {
  display: none;
  gap: 0.06849rem;
  align-items: center;
}
.container .right .actions .actionButton {
  overflow: hidden;
  white-space: nowrap;
}
.container .right .actions .actionButton font {
  display: block;
  width: 100%;
  overflow: hidden;
}