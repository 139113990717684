@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.skeleton_msi_homeBanner {
  height: 100%;
  width: 100%;
}