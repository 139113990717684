@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  margin: 0.5137rem 0 0.17123rem 0;
  height: 0rem;
  opacity: 0.4;
  border-bottom: 0.00856rem solid;
  border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgba(255, 255, 255, 0)) 1 1;
}