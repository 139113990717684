@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.msiSwiperSwitch-container .switchLeft .switchIcon,
.msiSwiperSwitch-container .switchRight .switchIcon {
  color: #fff;
}
.msiSwiperSwitch-container .switchLeft .switchIcon:hover,
.msiSwiperSwitch-container .switchRight .switchIcon:hover {
  color: var(--switch-color-hover);
}
.msiSwiperSwitch-container .switchLeft .switchIcon:focus-visible,
.msiSwiperSwitch-container .switchRight .switchIcon:focus-visible {
  color: var(--switch-color-focus);
}
.msiSwiperSwitch-container .switchLeft .switchIcon:active,
.msiSwiperSwitch-container .switchRight .switchIcon:active {
  color: var(--switch-color-active);
}
.msiSwiperSwitch-container .switchLeft .switchIcon.disabled,
.msiSwiperSwitch-container .switchRight .switchIcon.disabled {
  color: var(--switch-color-disabled);
  pointer-events: none;
}