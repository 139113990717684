@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  height: 100%;
  overflow: hidden;
  position: relative;
}
.container .searchButton {
  position: absolute;
  bottom: 0.42808rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}
.container .helpBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}