@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.skeleton_appBanner {
  display: flex;
  flex-direction: column;
  gap: 0.13699rem;
}
.skeleton_appBanner .skeleton_appBannerLeft {
  width: 1.35274rem;
  height: 1.35274rem;
}
.skeleton_appBanner .skeleton_appBannerRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.13699rem;
}
.skeleton_appBanner .skeleton_appBannerTitle {
  height: 0.41096rem;
  vertical-align: middle;
  width: 3.68151rem;
}
.skeleton_appBanner .skeleton_appBannerSubTitle {
  height: 0.20548rem;
  width: 2.14041rem;
  margin-bottom: 0.06849rem;
}
.skeleton_appBanner .skeleton_appBannerActions {
  display: flex;
  gap: 0.17123rem;
}
.skeleton_appBanner .skeleton_appBannerActions > span {
  height: 0.41096rem;
  flex: 1;
  max-width: 2.65411rem;
}