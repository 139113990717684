@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  text-align: center;
  background: radial-gradient(24.52% 46.74% at 50.33% 47.92%, rgba(81, 165, 201, 0.2) 0%, rgba(79, 165, 201, 0) 100%), radial-gradient(21.19% 36.37% at 63.08% 56.52%, rgba(179, 214, 97, 0.2) 0%, rgba(179, 214, 97, 0) 100%), radial-gradient(22.34% 37.44% at 32.98% 55.76%, rgba(255, 66, 165, 0.2) 0%, rgba(255, 66, 165, 0) 100%);
}
.container .header {
  font-size: 0.17123rem;
  font-weight: bold;
  line-height: 0.34247rem;
  margin-bottom: 0.06849rem;
}
.container .content {
  display: flex;
  flex-wrap: wrap;
  gap: 0.11986rem;
  padding: 0.11986rem;
  justify-content: center;
  align-items: center;
}

.categoryItem {
  height: 0.47945rem;
  background: var(--category-bg-color);
  border-radius: 0.25685rem;
  border: 0.00856rem solid var(--category-border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--category-color);
  transition: all 0.2s ease-in-out;
  box-shadow: var(--category-shadow);
  padding: 0.05137rem;
}
.categoryItem:hover {
  color: var(--category-color-hover);
  border-color: var(--primary-color);
  background: var(--category-bg-color-hover);
  transform: translateY(-0.01712rem);
}
.categoryItem .logo {
  width: 0.37671rem;
  height: 0.37671rem;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0rem 0.03425rem 0.06849rem 0rem rgba(0, 0, 0, 0.1);
  background: linear-gradient(180deg, #CCCCCC 0%, #FFFFFF 100%);
  border-image: linear-gradient(180deg, rgb(223, 240, 255), rgb(170, 214, 255)) 1 1;
}
.categoryItem .logo :global(.defaultImge) {
  background: unset !important;
}
.categoryItem .logo::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../../../images/circle_avatar_skeleton_without_fill.svg);
  background-size: cover;
  background-position: center;
}
.categoryItem .title {
  font-size: 0.13699rem;
  font-weight: bold;
  line-height: 1.5;
  padding: 0 0.05137rem 0 0.10274rem;
}