@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.button.strokeButton.whiteChangeButton.adapter-cloud-button {
  box-shadow: inset 0 0 0 0.01712rem rgba(255, 255, 255, 0.6);
}
.button.strokeButton.whiteChangeButton.adapter-cloud-button:hover {
  box-shadow: inset 0 0 0 0.01712rem rgba(255, 255, 255, 0.9);
}
.button.strokeButton.whiteChangeButton.adapter-cloud-button:focus-visible {
  box-shadow: inset 0 0 0 0.01712rem rgba(255, 255, 255, 0.9);
}
.button.strokeButton.whiteChangeButton.adapter-cloud-button:active {
  box-shadow: inset 0 0 0 0.01712rem rgba(255, 255, 255, 0.8);
}
.button.strokeButton.whiteChangeButton.adapter-cloud-button.disabled {
  box-shadow: inset 0 0 0 0.01712rem rgba(255, 255, 255, 0.5);
  pointer-events: none;
}