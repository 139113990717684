@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.imageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  transition: all 0.3s ease-in-out;
}
.imageContainer .imageContainerChild {
  transition: opacity 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}
.imageContainer.ratioDifferent, .imageContainer.includeSize {
  background: rgba(0, 0, 0, 0.5);
}
.imageContainer.center {
  justify-content: center;
  align-items: center;
}
.imageContainer.centerTop {
  justify-content: center;
  align-items: flex-start;
}
.imageContainer.centerBottom {
  justify-content: center;
  align-items: flex-end;
}
.imageContainer.leftCenter {
  justify-content: flex-start;
  align-items: center;
}
.imageContainer.leftTop {
  justify-content: flex-start;
  align-items: flex-start;
}
.imageContainer.leftBottom {
  justify-content: flex-start;
  align-items: flex-end;
}
.imageContainer.rightCenter {
  justify-content: flex-end;
  align-items: center;
}
.imageContainer.rightTop {
  justify-content: flex-end;
  align-items: flex-start;
}
.imageContainer.rightBottom {
  justify-content: flex-end;
  align-items: flex-end;
}
.imageContainer.includeSize .imageContainerChild {
  width: auto !important;
  height: auto !important;
}
.imageContainer.squareSize.containerHeightGtWidth .imageContainerChild {
  width: 100%;
  height: auto;
}
.imageContainer.squareSize.containerWidthGtHeight .imageContainerChild {
  width: auto;
  height: 100%;
}
.imageContainer.squareSize.containerSquareSize .imageContainerChild {
  width: 100%;
  height: 100%;
}