@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container {
  color: #fff;
}
.container .header {
  font-size: 0.13699rem;
  margin-bottom: 0.20548rem;
  text-align: center;
}
.container .content {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}
.container .content .contentWrap {
  display: grid;
  grid-template-columns: repeat(5, minmax(calc(20% - 0.16267rem), 1fr));
  gap: 0.16267rem;
  flex-wrap: nowrap;
  justify-content: center;
}

.itemContainer {
  display: flex;
  gap: 0.10274rem;
  align-items: center;
  cursor: pointer;
}
.itemContainer:hover img {
  transform: scale(1.2);
  transform-origin: center center;
}
.itemContainer .left {
  width: 0.54795rem;
  height: 0.54795rem;
  flex-basis: 0.54795rem;
  flex-shrink: 0;
}
.itemContainer .left img {
  transition: transform 0.3s ease-in-out;
}
.itemContainer .right {
  display: flex;
  flex-direction: column;
  gap: 0.03425rem;
  flex: 1;
  width: 0.85616rem;
}
.itemContainer .right .title {
  font-size: 0.11986rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.9;
}
.itemContainer .right .subTitle {
  font-size: 0.10274rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.6;
}