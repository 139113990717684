@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.ember-video-container {
  width: 4.19521rem;
  height: 100%;
  cursor: pointer;
}
.ember-video-container .ember-iframe-container {
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.ember-video-container iframe {
  border: none;
  width: 100%;
  height: 100%;
}
.ember-video-container .posturl-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.ember-video-container .posturl-container .posturl-container-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0.41096rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(closest-side at center, rgba(31, 35, 51, 0.8), rgba(0, 0, 0, 0));
}