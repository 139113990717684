@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.container .bannerInfo {
  padding-top: 0.78767rem;
  padding-bottom: 0.87329rem;
}
.container .bannerList :global .swiper .swiper-wrapper {
  padding: 0.42808rem 0 0;
}
.container .bannerList :global .game-carousel-section-carouselItem {
  text-align: center;
}
.container .bannerList :global .gameItemOverlayContainer {
  border-radius: 23%;
}
.container .bannerList :global .gameItemOverlayContainer::after {
  border-radius: 23% !important;
}
.container .skeletonContainer {
  margin: -0.42808rem -0.19692rem 0;
  overflow: hidden;
  padding: 0.42808rem 0.19692rem 0;
}