.translateX-enter,
.translateX-enter-active,
.translateX-exit,
.translateX-exit-done,
.translateX-exit-active,
.reTranslateX-enter,
.reTranslateX-enter-active,
.reTranslateX-exit,
.reTranslateX-exit-done,
.reTranslateX-exit-active,
.fade-enter,
.fade-enter-active,
.fade-exit,
.fade-exit-done,
.fade-exit-active {
  overflow: hidden;
}

.translateX-enter,
.translateX-exit,
.reTranslateX-enter,
.reTranslateX-exit {
  will-change: transform;
}

/* 入场动画开始 */
.translateX-enter {
  transform: translateX(100%);
  opacity: 0.8;
}

/*入场动画过程*/
.translateX-enter-active {
  transform: translateX(0);
  transition: transform 0.3s ease;
}

/*入场动画结束*/
.translateX-enter-done {
  transform: translateX(0);
}

/*离场动画开始*/
.translateX-exit {
  transform: translateX(0);
}

/*离场动画过程*/
.translateX-exit-active {
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  opacity: 0.8;
}

/* 反向back */
/*入场动画开始*/
.reTranslateX-enter {
  transform: translateX(-100%);
  opacity: 0.8;
}

/*入场动画过程*/
.reTranslateX-enter-active {
  transform: translateX(0%);
  transition: transform 0.3s ease;
}

/*离场动画开始*/
.reTranslateX-exit {
  transform: translateX(0%);
}

/*离场动画过程*/
.reTranslateX-exit-active {
  transform: translateX(100%);
  transition: transform 0.3s ease;
  opacity: 0.8;
}

/* 入场动画开始 */
.fade-enter {
  opacity: 0.8;
  will-change: opacity;
}

/*入场动画过程*/
.fade-enter-active {
  transition: opacity 0.3s ease-in;
  opacity: 1;
}

/*入场动画结束*/
.fade-enter-done {
  opacity: 1;
}

/*离场动画开始*/
.fade-exit {
  opacity: 0.8;
  will-change: opacity;
}

/*离场动画过程*/
.fade-exit-active {
  transition: opacity 0.3s ease;
  opacity: 0.8;
}
